/* --------------------------------------------- */
/*  Status Label Styling                         */
/* --------------------------------------------- */

.Status-Label {
    padding: 8px 15px;
    border-radius: 50px;
    margin: 0px;
    max-width: 300px;
    height: 35px;
    text-align: center;
    font-size: 13.5px;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: center;
    font-weight: 500;
}