/* Form Layout & Styling */
.ICTAssessment-Form {
    display: grid;
    row-gap: 10px;
    font-size: 16px;
}

.ICTAssessment-Form-Row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}

.ICTAssessment-Form-Row label {
    margin: 10px 0px;
}

.ICTAssessment-Form-Row input {
    padding: 20px 15px;
    color: black;
}

.ICTAssessment-Form-Row textarea {
    padding: 15px;
    color: black;
}

.ICTAssessment-Form-Row select { 
    color: black;
}

.ICTAssessment-Form-Radio {
    display: flex;
    align-items: center;
}

/* Project Owners Classes */
.ICTAssessment-Form-ProjectOwners {
    display: grid;
    grid-template-columns: 500px;
    align-items: center;
    row-gap: 10px;
}

.ICTAssessment-Form-New {
    background-color: rgba(132, 193, 212, 0.19);
    padding: 20px;
    border-radius: 5px;
}