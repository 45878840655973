.Requests-Dashboard-Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 400px 125px 125px 125px 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    'Requests-Pending-Approval-Pie Approvers-List Approvers-List Requests-Per-Day Requests-Per-Day'
    'Requests-Business-Unit Requests-Business-Unit Active-Approvals Requests-Pending-Approval-List Requests-Pending-Approval-List'
    'Requests-Business-Unit Requests-Business-Unit Total-Approved-Requests Requests-Pending-Approval-List Requests-Pending-Approval-List'
    'Requests-Business-Unit Requests-Business-Unit Total-Rejected-Requests Requests-Pending-Approval-List Requests-Pending-Approval-List'
    'Average-Duration-Approval-Type Average-Duration-Approval-Type Average-Duration-Approval-Type Rejected-Requests Rejected-Requests';
  padding: 0px;
  background-color: #016866;
}

.Requests-Pending-Approval-Pie {
  grid-area: Requests-Pending-Approval-Pie;
  padding: 10px;
  background-color: white;
}

.Requests-Business-Unit {
  grid-area: Requests-Business-Unit;
  padding: 10px;
  background-color: white;
  height: 395px;
}

.Requests-Pending-Approval-List {
  grid-area: Requests-Pending-Approval-List;
  padding: 10px;
  background-color: white;
  height: 395px;
  overflow-y: scroll;
}

.Requests-Per-Day {
  grid-area: Requests-Per-Day;
  padding: 10px;
  background-color: white;
}

.Rejected-Requests {
  grid-area: Rejected-Requests;
  padding: 10px;
  background-color: white;
}

.Active-Approvals {
  grid-area: Active-Approvals;
  padding: 10px;
  background-color: white;
}

.Approvers-List {
  grid-area: Approvers-List;
  padding: 10px;
  background-color: white;
}

.Average-Duration-Approval-Type {
  grid-area: Average-Duration-Approval-Type;
  padding: 10px;
  background-color: white;
}

.Total-Rejected-Requests {
  grid-area: Total-Rejected-Requests;
  padding: 10px;
  background-color: white;
}

.Total-Approved-Requests {
  grid-area: Total-Approved-Requests;
  padding: 10px;
  background-color: white;
}

.Costs-Dashboard-Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 'fit-content' 'fit-content' 'fit-content' 'fit-content' 'fit-content';
  gap: 10px 10px;
  grid-template-areas:
    'Requests-Costs-List Requests-Costs-List Requests-Costs-List Requests-Costs-List Requests-Cost-Comparison-Pie Requests-Cost-Comparison-Pie'
    'Requests-Driving-Revenue-List Requests-Driving-Revenue-List Requests-Driving-Funds-List Requests-Driving-Funds-List Requests-Estimated-Costs-Awaiting-Approval Requests-Estimated-Costs-Rejected'
    'Requests-Driving-Revenue-List Requests-Driving-Revenue-List Requests-Driving-Funds-List Requests-Driving-Funds-List Requests-Estimated-Costs-Approved Requests-Estimated-Costs-Approved'
    'Requests-Estimated-Costs-Bar Requests-Estimated-Costs-Bar Requests-Estimated-Costs-Bar Requests-BU-Estimated-Costs-Bar Requests-BU-Estimated-Costs-Bar Requests-BU-Estimated-Costs-Bar'
    'Requests-Estimated-Costs-Bar Requests-Estimated-Costs-Bar Requests-Estimated-Costs-Bar Requests-BU-Estimated-Costs-Bar Requests-BU-Estimated-Costs-Bar Requests-BU-Estimated-Costs-Bar';
  padding: 0px;
  background-color: #016866;
}

.Requests-Costs-List {
  grid-area: Requests-Costs-List;
  padding: 10px;
  background-color: white;
  overflow-y: scroll;
}

.Requests-Cost-Comparison-Pie {
  grid-area: Requests-Cost-Comparison-Pie;
  justify-self: center;
  width: 100%;
  padding: 10px;
  background-color: white;
}

.Requests-Driving-Revenue-List {
  grid-area: Requests-Driving-Revenue-List;
  padding: 10px;
  background-color: white;
  height: 280px;
  overflow-y: scroll;
}

.Requests-Driving-Funds-List {
  grid-area: Requests-Driving-Funds-List;
  padding: 10px;
  background-color: white;
  height: 280px;
  overflow-y: scroll;
}

.Requests-Estimated-Costs-Awaiting-Approval {
  grid-area: Requests-Estimated-Costs-Awaiting-Approval;
  padding: 10px;
  background-color: white;
  text-align: center;
}

.Requests-Estimated-Costs-Rejected {
  grid-area: Requests-Estimated-Costs-Rejected;
  padding: 10px;
  background-color: white;
  text-align: center;
}

.Requests-Estimated-Costs-Approved {
  grid-area: Requests-Estimated-Costs-Approved;
  padding: 10px;
  background-color: white;
  text-align: center;
}

.Requests-Estimated-Costs-Bar {
  grid-area: Requests-Estimated-Costs-Bar;
  padding: 10px;
  background-color: white;
}

.Requests-BU-Estimated-Costs-Bar {
  grid-area: Requests-BU-Estimated-Costs-Bar;
  padding: 10px;
  background-color: white;
}